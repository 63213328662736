define("discourse/plugins/scheduled-reply/discourse/initializers/scheduled-reply", ["exports", "discourse/lib/plugin-api", "discourse/models/composer"], function (_exports, _pluginApi, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initWithApi(api) {
    const siteSettings = api.container.lookup("service:site-settings");
    if (!siteSettings.scheduled_reply_enabled) return;
    _composer.default.serializeOnCreate("scheduled_reply_time");
    api.includePostAttributes("scheduled_reply_time");
    api.decorateWidget("post-article:before", dec => {
      let srt = dec.attrs.scheduled_reply_time;
      if (srt) {
        let str = "scheduled reply in ";
        srt = moment(dec.attrs.created_at).add(srt, "hours");
        const now = moment();
        if (srt < now) return;
        const diff = now - srt;
        const time = moment.duration(Math.abs(diff));
        const hours = time.days() * 24 + time.hours();
        const mins = time.minutes();
        if (hours) {
          str += `${hours} hour`;
          if (hours > 1) {
            str += "s";
          }
          str += " ";
        }
        if (mins) {
          str += `${mins} minute`;
          if (mins > 1) {
            str += "s";
          }
        }
        return dec.h("div.time-gap.small-action", [dec.h("div.topic-avatar"), dec.h("div.small-action-desc.timegap", str)]);
      }
    });
    api.reopenWidget("post", {
      buildClasses(attrs) {
        let classes = this._super(...arguments) || [];
        if (!(classes instanceof Array)) {
          classes = [classes];
        }
        if (attrs.scheduled_reply_time) {
          classes.push("scheduled-reply");
        }
        return classes;
      }
    });
  }
  var _default = _exports.default = {
    name: "scheduled-reply",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", initWithApi);
    }
  };
});