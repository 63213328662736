define("discourse/plugins/scheduled-reply/discourse/components/modal/scheduled-reply", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @closeModal={{@closeModal}} @title={{i18n "scheduled_reply.modal_title"}} class="scheduled-reply-modal">
    <:body>
      {{#each this.times as |t|}}
        {{d-button
          translatedLabel=(i18n "scheduled_reply.modal_btn_label" t=t)
          action=(action "schedule" t)
          class="btn-large btn-default schedule-time-btn"
        }}
      {{/each}}
    </:body>
  </DModal>
  
  */
  {
    "id": "4eAQmWat",
    "block": "[[[8,[39,0],[[24,0,\"scheduled-reply-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"scheduled_reply.modal_title\"],null]]],[[\"body\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"times\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,4],null,[[\"translatedLabel\",\"action\",\"class\"],[[28,[37,1],[\"scheduled_reply.modal_btn_label\"],[[\"t\"],[[30,2]]]],[28,[37,5],[[30,0],\"schedule\",[30,2]],null],\"btn-large btn-default schedule-time-btn\"]]]],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[\"@closeModal\",\"t\"],false,[\"d-modal\",\"i18n\",\"each\",\"-track-array\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/scheduled-reply/discourse/components/modal/scheduled-reply.hbs",
    "isStrictMode": false
  });
  class ScheduledReplyModal extends _component.default {
    times = [24, 48, 72];
    schedule(time) {
      const setVal = t => {
        this.model.composer.model.set("scheduled_reply_time", t);
      };
      setVal(time);
      this.closeModal();
      const isSaved = this.model.composer.save(true);
      if (isSaved) {
        isSaved.catch(_e => {
          setVal(null);
        });
      } else {
        setVal(null);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "schedule", [_object.action]))();
  }
  _exports.default = ScheduledReplyModal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ScheduledReplyModal);
});