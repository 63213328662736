define("discourse/plugins/scheduled-reply/discourse/connectors/composer-fields-below/scheduled-reply-btn", ["exports", "discourse/lib/show-modal", "@ember/application", "discourse/plugins/scheduled-reply/discourse/components/modal/scheduled-reply"], function (_exports, _showModal, _application, _scheduledReply) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const composer = (0, _application.getOwner)(this).lookup("controller:composer");
      component.setProperties({
        composer
      });
    },
    actions: {
      scheduleReply() {
        const modalService = (0, _application.getOwner)(this).lookup("service:modal");
        modalService.show(_scheduledReply.default, {
          model: {
            composer: this.composer
          }
        });
      }
    }
  };
});